import React from 'react';

import {
  ContainerComponent,
  SectionTitleComponent,
  FaqItemComponent,
} from '@/components';

const FAQ_LIST = [
  {
    question: '個人でもこのシステムを利用できますか？',
    answer:
      '本サービスは法人向けのサービスとなっており、法人様の店舗等でシステムを利用し、商品を販売いただくサービスとなります。',
  },
  {
    question: '導入にあたり必要なものはありますか？',
    answer:
      '導入にあたり必要なものとしては、ラベルに使用するコンテンツ・Wi-Fi環境・Android端末のご用意をお願いいたします。',
  },
  {
    question: '導入のフローは？',
    answer:
      'HPよりお問い合わせいただくと弊社担当からご連絡させていただきます。その後ご契約となりましたら、弊社からシステム等の貸与や研修を実施させていただき、完了次第営業開始となります。',
  },
  {
    question: 'フレーバーは何種類ありますか？',
    answer:
      '2023年4月時点では、レモンスカッシュ（350ml）、ウーロン茶（340ml）の2種です。',
  },
  {
    question: '専用ドリンクの賞味期限はどのくらいですか？',
    answer: '製造から12か月です。',
  },
  {
    question: 'ラベル上では、何を設定することができますか？',
    answer:
      'ラベル左面の画像とメインテキスト、右面の日時、場所、コンテンツ名が設定できます。',
  },
  {
    question: 'どのようなオペレーションで販売するものですか？',
    answer:
      'お客様の注文に応じてラベルを印刷し、ラベルを専用缶に貼り付けて販売する形になります。',
  },
] as const;

const FAQSection = () => (
  <section>
    <ContainerComponent size="large" className="py-10 tl:py-12">
      <SectionTitleComponent
        id="faq"
        title="FAQ"
        description="よくあるご質問"
      />
      <ul className="mt-6 tl:mt-15">
        {FAQ_LIST.map(({ question, answer }, index) => (
          <li
            key={index}
            className="border-t last:border-b border-gray-100 -mx-5 tl:mx-0"
          >
            <FaqItemComponent question={question} answer={answer} />
          </li>
        ))}
      </ul>
    </ContainerComponent>
  </section>
);

export default FAQSection;
